import Header from '../componentes/Header';
import Footer from '../componentes/Footer';


function Contato() {
  return (
<main>  
<Header />

<section className='contato'> 
    <h2> Contato da Paraquedistas Online! </h2>
        <article> O projeto de acessibilidade à informação da Paraquedistas Online conta com você! </article>
        <article> Mande o conteúdo que deseja compartilhar aqui, desde que seja instrução de qualidade. Vamos analisar todo o material com atenção. Pode ser apostilas, links de vídeos, slides ou qualquer material que você tenha guardado. </article>
        <article> E assim, cada um pode fazer uma pequena parte e juntos melhorarmos a segurança do nosso esporte compartilhando informação! </article>
    <h3> CONTATO </h3>
        <article> Telefone (Whats App): (XX) XXXXX-XXXX</article>
        <article> E-mail: XXXXX@paraquedistasonline.com.br </article>
        <input type="text" />

</section>

<Footer />
</main>
  )
}

export default Contato;