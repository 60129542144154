import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';


function ProvaCatAi() {
  return (
<main>  
<Header />

<section className='provaaff'>
<h2> SIMULADO PROVA CATEGORIA AI </h2>
    
</section>

<Footer />
</main>
  )
}

export default ProvaCatAi;