import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';
import '../../styles/Categoria/Categoria.css'

import FichaCDownload from '../../files/fichaProgC.pdf';
import ProvaCDownload from '../../files/provaCatC.pdf';


function CatC() {
  return (
<main>  
<Header />

<section className="imageCatAi">
    <h2> Categoria </h2>
    <h1> C </h1>
    <article>
      Encontre aqui material para atletas atualmente na Categoria C, que buscam a progressão para Categoria D.
    </article>
</section>  

<section className="bodyCatAi">
<section className='normasC'>
<h2> Normas </h2>
    <article> Veja aqui as normas de paraquedismo para a sua categoria, por tópico e por entidade. Você pode consultar as normas na íntegra também. </article> <br/>
    <Link to="/NormasCatAi"> <button> Consulte as normas aqui! </button> </Link>
    <br/>
</section>
    
<section className='fichaProgC'> 
    <h2> Ficha de Progressão </h2>
    <article> Veja sua ficha de progressão online da <strong> ABRA </strong>para a Categoria C. </article> <br/>
    <Link to="/FichaProgC"> <button> Ficha de Progressão da ABRA </button> </Link>
    <button> <a href={FichaCDownload} target="_blank"> </a> Veja a ficha em branco </button>
    <button> <a href={FichaCDownload} download> Faça o download da ficha para categoria C </a></button>
</section>

<section className='provasC'> 
    <h2> Prova de Mudança de Categoria </h2>
    <article> Consulte e estude a prova para mudança de Categoria - de cat B para cat C. </article> 
    <br/>
    <button> <a href={ProvaCDownload} download> Faça o download da prova de mudança para categoria C da ABRA </a></button> 
    <button> <a href={ProvaCDownload} download> Veja a prova de mudança para Cat C </a></button> <br/>
    <Link to="/ProvaCatC"> <button> Estude online com este simulado explicativo </button> </Link>
    <button> Envie sua prova para correção e arquivo por aqui </button> 
</section>

<section className='extraCatC'> 
    <h2> Material Extra </h2>
    <article> Encontre aqui material extra para a sua categoria. </article> <br/>
    <Link to="/ExtraCatAi" > <button> Acesse Material Extra por aqui! </button> </Link>
    <br/>
</section>

<section className='video-aulas'> 
    <h2> Vídeo-aulas </h2>
    <article> Encontre aqui um repositório completo de vídeos, separados por Canal, para estudar por 
        tópico, onde e quando quiser. </article> <br/>
    <Link to="/VideoAulas"> <button>Veja todos os vídeos do acervo </button> </Link> <br/>
    <h3> Sugestões de vídeos para a sua categoria: </h3>
        <iframe width="420" height="315" src="https://www.youtube.com/embed/zjLBUiWv09g"> </iframe>
        <iframe width="420" height="315" src="https://www.youtube.com/embed/LmM9ANA5ze4"> </iframe>
    <br/>
</section>
</section>
<Footer />
</main>
  )
};

export default CatC;
