import { Link } from "react-router";
import '../styles/Header.css';
import Contato from '../pages/Contato.js';
import Login from '../pages/Login.js';

function Header() {
  return (
<main>  

  <header>
    <div className="header-links">
      <Link to="/"> Home </Link>
      <Link to="Contato"> Contato </Link>
    </div>
    
    <div>
      <button className="header-button"> <Link to="/Login"> Login </Link> </button>
    </div>
  </header>
</main>
  )
}

export default Header;
