import { Link } from "react-router";
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import '../styles/Coaches.css'

import LogoAbra from '../img/logo/logoAbra.png';

function Coaches() {
    return (
  <main>  
  <Header />
    <section>
        <h2> ESPAÇO DOS COACHES </h2>
        <article> Aqui disponibilizamos um espaço para todos os coaches que querem divulgar seu
          material de maneira digital, seja por vídeos, apostilas, slides ou artigos.
          Acompanhe de perto o trabalho destes profissionais e aproveite para aprender gratuitamente
          com os melhores Coaches do Brasil. </article>

          <h2> ops...PAGINA EM DESENVOLVIMENTO... </h2>
        <br/>

        <section>

          <div className="apresentacaocoach">
            <div className="imgcoach">
              <img src={LogoAbra} />
            </div>

            <div>
              <h3> Nome Coach </h3>
              <article>Localidade: Boituva - SP</article>
              <article>O XXXX é especialista em FF e Deslocs.  </article>
              <button> Canal do XXXX </button>
            </div>
          </div>
        </section>

        <section>
          <div className="apresentacaocoach">
            <div className="imgcoach">
              <img src={LogoAbra} />
            </div>

            <div className="nomecoach">
              <h3>Nome do coach</h3>
              <article>Localidade: Boituva - SP</article>
              <article>O fulano é especialista em FF e tem 1000 saltos.. </article>
              <button> Acesse o Canal </button>
            </div>
          </div>

          <div>
          </div>
        </section>
          <br/>
        <section>
          <div className="apresentacaocoach">
            <div className="imgcoach">
              <img src={LogoAbra} />
            </div>
              <br/>
            <div>
              <h3>Nome do coach</h3>
              <article>Localidade: Boituva - SP</article>
              <article>O fulano é especialista em FF e tem 1000 saltos.. </article>
              <button> Acesse o Canal </button>
            </div>
          </div>
        </section>
          <br/>
        <section>
          <div className="apresentacaocoach">
            <div className="imgcoach">
              <img src={LogoAbra} />
            </div>
              <br/>
            <div>
              <h3>Nome do coach</h3>
              <article>Localidade: Boituva - SP</article>
              <article>O fulano é especialista em FF e tem 1000 saltos.. </article>
              <button> Acesse o Canal </button>
            </div>
          </div>
        </section>


    </section>
  <Footer />
</main>
  )
}

export default Coaches;