import { Link, useNavigate } from "react-router";
import '../styles/Footer.css';

function Footer() {
  const navigate = useNavigate();
    return (
  <main>  
  
    <footer>
      <div className="footer">
        <button className="btn_home" onClick = {() => navigate('/')}> Home </button> 
        <button className="btn_back" onClick ={()=> navigate(-1)}> Voltar </button>
      </div>
    </footer>
  </main>
    )
  }
  
  export default Footer;
  