import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';
import Ficha from '../../files/fichaProgA.pdf';
import '../../styles/Categoria/Categoria.css'

import FichaADownload from '../../files/fichaProgA.pdf';
import ProvaADownload from '../../files/provaCatA.pdf';
import ProvaAffDownload from '../../files/provaAff.pdf';

function CatA() {
  return (
<main>  
<Header />

<section className="imageCatAi">
    <h2> Categoria </h2>
    <h1> A </h1>
    <article>
      Encontre aqui material para atletas atualmente na Categoria A, que buscam a progressão para Categoria B.
    </article>
</section>  

<section className="bodyCatAi">
<section className='normasAi'>
<h2> Normas </h2>
    <article> Veja aqui as normas de paraquedismo para a sua categoria, por tópico e por entidade. Você pode consultar as normas na íntegra também. </article> <br/>
    <Link to="/NormasCatAi"> <button> Consulte as normas aqui! </button> </Link>
    <br/>
</section>
    

<section className='apostilaAff'> 
    <h2> Apostila AFF </h2>
    <article> Consulte a apostila de AFF e Reciclagem da Paraquedistas Online. </article> <br/>
    <Link to="/ApostilaAff"> <button> Consulte a Apostila AFF </button> </Link>
    <button> <a href="" download> Faça o download da apostila </a></button>
    <br/>
</section>

<section className='provaAff'> 
    <h2> Provas Reciclagem </h2>
    <article>Veja a Prova de término de curso teórico do AFF e Reciclagem. </article>
    <br/>
    <button> <a href={ProvaAffDownload} download> Faça o download da prova de AFF </a></button>
    <Link to="/ProvaAff"> <button> Estude online com este simulado explicativo </button> </Link>
    <button> Envie sua prova para correção e arquivo por aqui </button> 
    <br/>
</section>

<section className='fichaProgAi'> 
    <h2> Ficha de Progressão </h2>
    <article> Veja sua ficha de progressão online da <strong> ABRA </strong>para a Categoria B. </article> <br/>
    <Link to="/FichaProgA"> <button> Ficha de Progressão da ABRA </button> </Link>
    <button> <a href={FichaADownload} target="_blank"> </a> Veja a ficha em branco </button>
    <button> <a href={FichaADownload} download> Faça o download da ficha para categoria B </a></button>
</section>

<section className='provasAi'> 
    <h2> Prova de Mudança de Categoria </h2>
    <article> Consulte e estude a prova para mudança de Categoria - de cat A para cat B. </article> 
    <br/>
    <button> <a href={ProvaADownload} download> Faça o download da prova de mudança para categoria B da ABRA </a></button> 
    <button> <a href={ProvaADownload} download> Veja a prova de mudança para Cat B </a></button> <br/>
    <Link to="/ProvaCatA"> <button> Estude online com este simulado explicativo </button> </Link>
    <button> Envie sua prova para correção e arquivo por aqui </button> 
</section>

<section className='extraCatAi'> 
    <h2> Material Extra </h2>
    <article> Encontre aqui material extra para a sua categoria. </article> <br/>
    <Link to="/ExtraCatAi" > <button> Acesse Material Extra por aqui! </button> </Link>
    <br/>
</section>

<section className='video-aulas'> 
    <h2> Vídeo-aulas </h2>
    <article> Encontre aqui um repositório completo de vídeos, separados por Canal, para estudar por 
        tópico, onde e quando quiser. </article> <br/>
    <Link to="/VideoAulas"> <button>Veja todos os vídeos do acervo </button> </Link> <br/>
    <h3> Sugestões de vídeos para a sua categoria: </h3>
        <iframe width="420" height="315" src="https://www.youtube.com/embed/zjLBUiWv09g"> </iframe>
        <iframe width="420" height="315" src="https://www.youtube.com/embed/LmM9ANA5ze4"> </iframe>
    <br/>
</section>
</section>
<Footer />
</main>
  )
};

export default CatA;
