import { Link } from "react-router";
import Header from './componentes/Header';
import Footer from './componentes/Footer.js';
import './App.css';
import Contato from './pages/Contato.js';
import SaibaMais from './pages/SaibaMais.js';

import VideoDestaque from './componentes/VideoDestaque.js';
import Parceiros from './componentes/Parceiros.js';

import Conversor from './pages/Tools/Conversor.js';
import CargaAlar from './pages/Tools/CargaAlar.js';
import Caderneta from './pages/Tools/CadernetaSaltos.js';
import FichaOnline from './pages/Tools/FichaOnline.js';
import CadernetaReserva from './pages/Tools/CadernetaReserva.js';

import VideoAulas from './pages/VideoAulas.js';
import Coaches from './pages/Coaches.js';

import CatA from './pages/CatA/CatA.js';
import CatB from './pages/CatB/CatB.js';
import CatC from './pages/CatC/CatC.js';
import CatD from './pages/CatD/CatD.js';

function App() {
  return (
<main>  
<Header />
 
  <section className="image-1">
    <h2> Paraquedistas </h2>
    <h2> Online</h2>
    <article>
      A página da Paraquedistas Online foi feita para ajudar você a aprender mais sobre paraquedismo, e manter seus saltos seguros.
    </article>
    <button className="SaibaMais"> <Link to="/SaibaMais"> Saiba mais... </Link> </button>
</section>  


<section className="acess">
    <h2> Acesse o material </h2>
    <article>Aqui você acessa todo material didático, de acordo com a sua categoria ou tipo de instrução. <br/>
    A missão da Paraquedistas Online é oferecer material de ensino de qualidade, tornando o conhecimento acessível a todos. <br/>
    Seja bem-vindo a sua nova escola online de paraquedismo. Escolha como quer acessar:
    </article>
    <div className='div_btn'>
    <div className="button-cat">
      <button> <Link to="/CatAi"> Categoria AI </Link> </button> <br/>
      <button> <Link to="/CatA"> Categoria A </Link> </button> <br/>
      <button> <Link to="/CatB"> Categoria B </Link> </button> <br/>
      <button> <Link to="/CatC"> Categoria C </Link> </button> <br/>
      <button> <Link to="/CatD"> Categoria D </Link> </button>
    </div>

    <div className="button-instruction">
      <button> Coach </button> <br/>
      <button> IASL </button> <br/>
      <button> IAFF </button> <br/>
      <button> Tandem Pilot </button> <br/>
      <button> Examinador </button>
    </div>
    </div>
  </section>


<section className="class-extra" >
    <h2> Material Extra </h2>
    <article> Aqui você encontra conteúdo exclusivo sobre os temas mais importantes para a sua segurança.</article>
    <button> Equipamento </button>
    <button> Metereologia </button>
    <button> Safety </button>
</section>

<section className="tools">
  <h2> Ferramentas </h2>
  <article> Encontre as melhores ferramentas para auxiliar seu dia-a-dia.</article>
    <button> <Link to="/Conversor"> Conversor de vento </Link> </button> 
    <button> <Link to="/CargaAlar"> Calcular Carga Alar </Link> </button> 
    <button> <Link to="/FichaOnline"> Ficha de Progressão Online </Link> </button> <br/>
    <button> <Link to="/Caderneta"> Caderneta de Saltos Online </Link>  </button> 
    <button> <Link to="/CadernetaReserva"> Caderneta do Reserva Online </Link> </button>
</section>

<section className="coaches" >
    <h2> Canal do Coach </h2>
    <article> Escolha o seu Coach favorito e acesso todo o conteúdo dele por aqui. </article>
    <button> <Link to="/Coaches"> Conteúdo por Coach </Link> </button>
</section>

<section className="class-extra" >
    <h2> Veja vídeos </h2>
    <article> Aqui tem um repositório completo de vídeos no youtube. Estão separados por canal, assim você consegue acompanhar seus cocahes favoritos. Conheça canais novos e mergulhe nos maior repositório de vídeos de paraquedismo do Brasil. </article>
    <button> <Link to="VideoAulas"> Video-aulas por tópico </Link> </button>
</section>

<section>
  <Parceiros />
</section>
<br/>

<Footer />

</main>
  )
}

export default App;
