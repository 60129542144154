import { Link } from "react-router";
import {useRef} from 'react';
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';


function ProvaAff() {

//array de objetos

const quizz = [
  {
      question: "Quais são os 3 punhos do paraquedas, onde se localizam e para que cada um deles serve?",
      answer: "1) Punho comando do principal - atrás lado direito - comando do principal. 2) Punho desconector do principal - frente lado direito - desconectar o principal. 3) Punho comando do reserva - frente lado esquerdo - comandar o reserva"
  },
  {
      question: "Explique qual a principal função do slider.",
      answer: "Reduzir a velocidade de abertura do velame",
  },
  {
      question: "Descreva o funcionamento do sistema RSL.",
      answer: "Forma um link separável entre o tirante principal e o pino (ou cabo) do reserva, assim quando o velame principal é desconectado ele aciona o reserva, se o RSL estiver conectado",
  },
  {
      question: "O DAA aciona qual dos 2 velames e em que altura? Descreva o seu funcionamento.",
      answer: "Aciona o reserva, entre 1050 e 1200ft (depende do fabricante). O DAA mede velocidade e altura, acionando o reserva (cortando o loop) se estiver em velocidade alta a baixa altura. Consultar os manuais do fabricante para saber a velocidade e altura exata de cada marca.",
  },
  {
      question: "Supondo que o punho de acionamento do principal não foi acionado, o RSL irá funcionar ao desconectar o principal?",
      answer: "Não, pois o tirante permanece alojado, sem puxar a fita do RSL.",
  },
  { 
      question: "Em qual parte do velame as linhas de freio se conectam?",
      answer: "Na parte traseira, ou bordo de fuga. É a ponta da cauda do velame.",
  },
  { 
      question: "Qual será sua posição em relação ao solo ao sair da aeronave? Explique o motivo.",
      answer: "Perpendicular ao solo, visando apresentar posição selada ao vento relativo.",
  },
  {
      question: "De quem você recebe ordens no caso de uma pane na aeronave?",
      answer: "Do instrutor e do piloto.",
  },
  { 
      question: "Por que é importante proteger os punhos estando próximos ou dentro da aeronave?",
      answer: "Mantem eles nos devidos lugares e evita abertura acidental ou prematura.",
  },
  { 
      question: "Na Box Position, onde encontra-se o CG (centro de gravidade)?", 
      answer: "Quadril",
  },
  { 
      question: "Porque é necessária a posição selada, simétrica e relaxada em queda livre?",
      answer: "Para manter a estabilidade",
  },
  { 
      question: "Supondo que um paraquedista saia de uma aeronave a 10 mil pés e comande seu paraquedas a 6 mil pés, quantos segundos de queda livre terá em média?", 
      answer: "Dez Segundos saída + 18 segundos QL = 28 seg. Estima-se 10 segundos de subterminal, e após isso 6 segundos a cada mil pés.",
  },
  { 
      question: "Descreva a posição do corpo no momento do comando",
      answer: "Posição selada, braço direito levado ao punho escorregando pela lateral do corpo, braço esquerdo compensando o movimento esticado em frente a 	cabeça",
  },
  { 
      question: "No momento do comando do paraquedas inicia-se uma contagem de 5 segundos, explique o motivo.",
      answer: "Tempo de abertura do velame principal", 
  },
  { 
      question: "Descreva o check visual.",
      answer: "Velame retangular, células infladas, linhas esticadas e desembaraçadas, slider baixo.", 
  },
  { 
      question: "Descreva o check funcional.",
      answer: "Flare por 3 segundos, olha, curva de 90º a esquerda, olha, curva de 90º a direita.",
  },
  { 
      question: "Qual a direção ideal de pouso em relação ao vento?",
      answer: "Vento de nariz.",
  },
  { 
     question: "Para onde deve-se ir (caso já não esteja) imediatamente após os cheques de velame?", 
      answer: "Área de espera.",
  },
  {
      question: "Estando abaixo de 1000 ft já no circuito, quantos graus de curvas pode-se fazer?",
      answer: "90º",
  },
  { 
      question: "Estando abaixo de 300 ft, quantos graus de curvas pode-se fazer?",
      answer: "45º",
  },
  { 
      question: "Quais as prioridades no pouso?",
      answer: "- Pousar em uma área limpa, livre de obstáculos. Pousar com vento de nariz, e voando uma linha reta. Fazer flare pelo menos até a metade (reduzir velocidade de vôo). Se necessário, fazer rolamento",
  },
  {
      question: "Qual é a melhor forma de evitar uma colisão de velames quando está fazendo uma curva?",
      answer: "Estar atento ao espaço aéreo e olhar na direção da curva antes de executar a manobra.",
  },
  { 
      question: "Descreva o procedimento para pouso em linhas de alta tensão.",
      answer: "Evite essas áreas antes enquanto estiver descendo, curva mínima necessária para evitar os fios (curva rasa), pouse paralelamente aos fios, pouso a meio freio, rolamento. Tente tocar um fio de cada vez, aguarde resgate e a confirmação que a energia estará desligada.",
  },
  { 
      question: "Qual a ação mais importante de um salto?",
      answer: "Comandar.",
  },
  { 
      question: "Em caso de pane de aeronave, o que você faz entre as alturas de 0 a 1500ft?",
      answer: "Permanecer na aeronave, exceto pane estrutural ou fogo. Seguir orientações dos Instrutores e do Piloto.",
  },
  { 
      question: "Em caso de pane de aeronave, o que você faz entre as alturas de 1500 a 3500ft?",
      answer: "Sair e acionar o reserva. Seguir orientações dos Instrutores e do Piloto.",
  },
  { 
      question: "Em caso de pane de aeronave, o que você faz acima de 3500ft?",
      answer: "Sair e acionair o principal. Seguir orientações dos Instrutores e do Piloto.",
  },
  { 
      question: "Se perceber que alguma parte do seu equipamento abriu, estando dentro da aeronave (porta fechada), o que fazer?",
      answer: "Afastar-se da porta, desequipar, voltar ao solo com a aeronave.",
  },
  { 
      question: "Se no momento da saída, parte do seu equipamento abriu e expôs-se ao vento relativo, o que fazer?",
      answer: "Sair imediatamente da aeronave",
  },
  { 
      question: "Qual a diferença entre panes e anormalidades?",
      answer: "Anormalidades são situações que a princípio eu resolvo ou convivo, desde que passe no check visual e funcional. Panes não são seguras de navegar, requerem procedimento de emergência.",
  },
  {
      question: "O que fazer em caso de uma abertura fora de sequencia?",
      answer: "Comandar o paraquedas principal. Se após duas tentativas não conseguir, procedimento de emergência.",
  },
  { 
      question: "Descreva o procedimento de emergência.",
      answer: "Olha a bananinha vermelha, pega com as duas mão. Olha o punho de metal, puxa a bananinha, pega e puxa o punho de metal. Sela."
  },
  { 
      question: "Em qual momento é identificado uma pane e o que deverá ser feito imediatamente?",
      answer: "No check visual ou funcional. Pane sempre requer o procedimento de emergência.",
  },
  { 
      question: "Sabendo que em todos os caso de anormalidades, deve-se fazer o cheque funcional, seja para resolver ou conviver com a situação, exceto uma. Qual?",
      answer: "Twist.",
  },
  {
      question: "Em caso de twist de baixa velocidade, o que fazer?",
      answer: "Manter os batoques alojados, separar os tirantes com as mãos, chutando para o lado contrário do giro (desfazendo a torção).",
  },
  { 
      question: "Até que altura deve ser tomado a decisão de fazer um procedimento de emergência (recomendado para alunos e atletas pouco experientes?",
      answer: "2500ft",
  },
  { 
      question: "Quais são as formas de evitar dois velames abertos?",
      answer: "1) Check de equipamento. 2) Cuidado com a movimentação após equipado, sempre protegendo punhos e pinos. 3) Comandar na altura correta. 4) Treinar procedimento de emergência para executá-lo corretamente.",
  },
  { 
      question: "O que fazer em caso de 2 velames abertos, um na frente do outro?",
      answer: "BIPLANO: manter os batoques alojados, navegar pelos tirantes de trás do paraquedas da frente, curvas suaves, reta Boeing para pouso, rolamento.",
  },
  {
      question: "O que fazer em caso de 2 velames abertos, lado a lado?",
      answer: "SIDE BY SIDE: manter os batoques alojados, navegar pelos tirantes de traseiros internos, curvas suaves, reta Boeing para pouso, rolamento.",
  },
  { 
      question: "O que fazer em caso de 1 velame inflado e outro fechado?",
      answer: "Recolher o velame desinflado com cuidado, coloca-lo entre as pernas e navegar o velame inflado",
  },
  { 
      question: "O que fazer em caso de downplane?",
      answer: "DOWN PLANE: liberar RSL e desconectar principal (PE).",
  },
  { 
      question: "Explique como é feito um pouso fora da área",
      answer: "Identificar área de pouso livre de obstáculos (o mais livre possível). Identificar vento, planejar navegação para esta nova área. Fazer rolamento ao tocar o solo.",
  },
  { 
      answer: "O que fazer estando em rota de colisão FRONTAL com outro paraquedista?",
      question: "Curva 90º a direita",
  },
  { 
      question: "Em qual momento da navegação devemos estar atento, olhando tanto para os lados, quanto para baixo antes de fazer uma curva?",
      answer: "Todos.",
  },
  {
      question: "Estatisticamente, qual o maior motivo de contusões e acidentes durante o pouso?",
      answer: "Curva baixa.",
  }
];

const verifyBtn = document.querySelector("#verifyBtn");
const nextBtn = document.querySelector("#nextBtn");
let quizzQuestion = document.querySelector("#question");
let quizzAnswer = document.querySelector("#answer");
let index = "";

function nextQuestion() {
//  index = Math.floor(Math.random() * quizz.length);
//  quizzQuestion.textContent = quizz[index].question;
//  quizzAnswer.textContent = "";
  return index;
}

function verifyAnswer() {
//  if(index != "") {
//  quizzAnswer.textContent = quizz[index].answer;
//  } else {
//      quizzAnswer.textContent = "Faça uma pergunta.";
//  }
}

//nextBtn.addEventListener("click", nextQuestion);
//verifyBtn.addEventListener("click", verifyAnswer);
return (
<main>  
<Header />

<section className='provaaff'>
<h2> SIMULADO PROVA AFF e RECICLAGEM </h2>
<article> Estas pergunts são da teoria do AFF. </article>
<div class="quizz">
  <article classNamen="question"> Clique no botão "Outra pergunta" para gerar uma questão aleatória sobre paraquedismo. </article>
  <article className="answer"> </article>
  <button class="button" id="verifyBtn" onClick={verifyAnswer()}> Verificar resposta </button>
  <button class="button" id="nextBtn" onClick={nextQuestion()}> Outra pergunta </button>
</div>

</section>

<Footer />
</main>
  )
}

export default ProvaAff;