import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';
import VideoDestaque from '../../componentes/VideoDestaque.js';
import '../../styles/VideoAulas1.css'

function fridayFreakout() {
  return (
<main>  
<Header />

<section className='videoaulas1'>
<h2> FRIDAY FREAKOUT </h2>
    <article> Encontre aqui alguns dos vídeos mais acessados do <strong> Friday Freakout</strong>.</article> <br/>

    <section>
    <VideoDestaque />
    </section>

    <div className='fridayFreakout'>
    <h2> Friday Freakout </h2>  
    <article> Veja os vídeos mais acessados do Friday Freakout. </article> <br/>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/wCrvQ_xy_LA"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/p8TQ97FyMZI"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/G2-1bDB2gWU"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/pKne-VNApCI"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/gAw-fWkATLw"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/2D3b4U-4eRk"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/qiu61JUcqgg"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/K5SOrhK1E0Q"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/GS0ED9m-tmU"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/bKuSlsii64A"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/z4nILTHZAuU"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/lOF9gqft8FI"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/7mssCTYiFBg"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/8yBlL-CQn0I"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/gWE7A8UoYaY"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/zoWuwjbv9xw"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/2XyxIAOw988"> </iframe>

    </div>

</section>

<Footer />
</main>
  )
}

export default fridayFreakout; 