import { Link } from "react-router";
import '../styles/Componentes/HeaderTools.css';

function Ferramentas() {
  return (
<main>  

<section className="HeaderTools">
    <h2> FERRAMENTAS </h2>
</section>  

</main>
  )
}

export default Ferramentas;
