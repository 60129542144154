import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';


function ApostilaAff() {
  return (
<main>  
<Header />

<section className='apostilaAff'>
<h2> Apostila AFF </h2>
    <article> Seja bem vindo à Apostila do Método Acelerated Free Fall - AFF. Essa é a forma mais 
     moderna e segura de se ingressar no esporte de paraquedismo. Esta apostila digital é um apoio à
     instrução, ainda sendo necessário acompanhamento formal de um instrutor reconhecido para o seu 
     curso. A nossa intenção é reforçar o aprendizado, permitindo revisões e detalhamento da informação.
    </article>

    <article>
      Nesta aportila teremos os seguintes capítulos:
      1. Introdução: aqui falaremos de normas, orientações gerais sobre o curso e o seu dia do primeiro
      salto.
      2. Equipamento: aqui teremos a instrução completa sobre os componentes de um paraquedas, como
      eles funcionam e como operar o equipamento.
      3. Queda Livre: Aqui neste momento veremos o que acontece desde o momento que saimos da aeronave,
      até o momento que abrimos o paraquedas.
      4. Navegação: Esta porção do salto corresponde desde o momento que o paraquedas é aberto, até 
      chegarmos ao solo.
      5. Emergências - parte 1: veja todas as situações adversas e de emergência que você pode encontrar
      no seu salto. A parte um trata de emergências em aeronave, saída, queda livre e comando.
      6. Emergências - parte 2: aqui você encontra as situações de emergência desde a abertura do paraquedas
      até o pouso.
      7. Sequencia da Salto: Veja toda a sequencia prevista do salto, do embarque ao pouso.
    </article>

    <section>
      <h2> INTRODUÇÃO </h2>
      <article> lorem ipsum </article>
    </section>

    <section>
      <h2> EQUIPAMENTO </h2>
      <article> lorem ipsum </article>
    </section>

    <section>
      <h2> QUEDA LIVRE </h2>
      <article> lorem ipsum </article>
    </section>

    <section>
      <h2> NAVEGAÇÃO </h2>
      <article> lorem ipsum </article>
    </section>

    <section>
      <h2> EMERGÊNCIAS - PARTE 1 </h2>
      <article> lorem ipsum </article>
    </section>

    <section>
      <h2> EMERGÊNCIAS - PARTE 2 </h2>
      <article> lorem ipsum </article>
    </section>

    <section>
      <h2> SEQUENCIA DE SALTO </h2>
      <article> lorem ipsum </article>
    </section>

</section>

<Footer />
</main>
  )
}

export default ApostilaAff;