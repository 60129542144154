import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';
import VideoDestaque from '../../componentes/VideoDestaque.js';
import '../../styles/VideoAulas1.css'

function BlueSkies() {
  return (
<main>  
<Header />

<section className='videoaulas1'>
<h2> BLUE SKIES - FUN DAYS </h2>
    <article> Encontre aqui os melhores vídeos do canal <strong>Blue Skies - Fun Days.</strong></article> <br/>

    <section>
    <VideoDestaque />
    </section>

    <div className='windup'>
    <h2> BLUE SKIES - FUN DAYS </h2>  
    <div className='fundays'>
    <h3> BLUE SKIES - FUN DAYS </h3>  
        <article> Veja vídeos do Canal Blue Skies - Fun Days. </article>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/upAZrlXAsu8"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/yDntUQYi5FE"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/ZXpMuKP-IPA"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/kLzIstQy4Wg"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/-CU5UqaK-nQ"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/dMngkFCKay4"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/JwVURNKiUFY"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/bHwEgtLZmSU"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/-wFc1idkbLI"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/cgPBH7Ek2iE"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/OmA6LnTkznc"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/UALIR49J43U"> </iframe>
    </div>
    </div>
</section>

<Footer />
</main>
  )
}

export default BlueSkies; 