import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import Foto from "../img/MatrizTolerabilidade.png";

function SaibaMais() {
  return (
<main>  
<Header />

<section className='saibamais'> 
    <h2> Conheça a Paraquedistas Online! </h2>
        <article> O projeto de acessibilidade à informação da Paraquedistas Online começou com um sonho de 
          melhorar a segurança no esporte, aprimorando a qualidade da informação. </article>
          <br/>
        <article> MISSÃO: Ser um repositório de informações de qualidade em instrução do paraquedismo. </article>
        <article> VISÃO: Permitir que todos os paraquedistas acessem conteúdo de qualidade, a fim de tornar 
          seus saltos mais seguros. Auxiliar os instrutores nas tarefas de check, criando um ambiente 
          virtual de trabalho e mantendo o histórico de atividades digitalizado. </article>
        <article> VALORES: Segurança, Ensino de qualidade, Acessibilidade das informações, Comunidade
          paraquedista, Normas e Treinamentos. </article>
        
    <h3> Segurança </h3>
      <article> Conhecimento é crucial para manter a segurança da operação. A instrução de qualidade é 
      fundamental no paraquedismo, pois garante a segurança e o desenvolvimento técnico de cada 
      praticante. </article>
    
    <h3> Matriz de Tolerabilidade </h3>
    <article> A Matriz de Tolerabilidade da ICAO - Organização Internacional da Aviação Civil - é uma 
      ferramenta utilizada para avaliar e gerenciar riscos no setor de aviação. Baseada nos 
      princípios de gerenciamento de segurança operacional do Safety Management System - SMS, essa 
      matriz classifica os riscos com base em sua probabilidade de ocorrência e sua gravidade. 
      O objetivo principal é ajudar organizações e reguladores a determinar quais riscos são 
      aceitáveis, quais requerem mitigação e quais são inaceitáveis, demandando ações imediatas.</article>
        <br/>
<article> A matriz é composta por eixos que cruzam diferentes níveis de probabilidade: desde "improvável" até 
"frequente"; e tem níveis de gravidade: de "insignificante" a "catastrófico". Com isso, os riscos são 
categorizados em áreas de tolerabilidade, que geralmente são: </article>
<article>
- Aceitável sem mitigação: Não requerem ação específica. <br/>
- Aceitável com controle mitigatório: Exige medidas para reduzir ou controlar o risco. <br/>
- Inaceitável: Deve ser evitado ou eliminado. </article>

<img src={Foto} />

<article> Na prática, essa ferramenta é amplamente utilizada na aviação comercial e esportiva para criar 
  padrões de segurança mais altos e garantir que os riscos sejam devidamente controlados, contribuindo 
  para a prevenção de acidentes e incidentes. A Matriz também nos mostra que há duas formas de gerenciar riscos: diminuir a gravidade da 
  ocorrência, ou diminuir a frequência. Assim o conhecimento teórico se torna imprescindível, pois
  atua tanto reduzindo a frequência, quanto melhorando a resposta caso ocorra, minimizando a gravidade.
</article>
    <br/>
<article> Saiba mais sobre o <a src="https://www.anac.gov.br/assuntos/legislacao/legislacao-1/iac-e-is/is/is-153-63-001" target='_blank'> Instrução Suplementar 153.63-001 da ANAC </a> que trata sobre o Plano de 
  Gerenciamento de Segurança Operacional.
</article>
    <br/>

</section>

<Footer />
</main>
  )
}

export default SaibaMais;