import { Link } from "react-router";
import '../styles/Componentes/Parceiros.css';
import LogoAbra from "../img/logo/logoAbra.png";
import LogoAeroplay from "../img/logo/logoAeroplay.png";
import LogoSetor2 from "../img/logo/logoSetor2.png";
import LogoSkyradical from "../img/logo/logoSkyradical.png";

function Parceiros() {
  return (
<main>  

<section className="partners"> 
    <h2> Parceiros </h2>
    <div> 
      <a href="www.abra.org.br" target="_blank"> <img src={LogoAbra} /> </a>
      <img src={LogoAeroplay} />
      <img src={LogoSkyradical} />
      <img src={LogoSetor2} />
    </div>
</section>

</main>
  )
}

export default Parceiros;