import { Link } from "react-router";
import Header from '../../componentes/Header.js';
import HeaderTools from '../../componentes/HeaderTools.js';
import Footer from '../../componentes/Footer.js';
import Login from '../Login.js';

import {useRef} from 'react';
import {useReactToPrint} from 'react-to-print';
import '../../styles/Tools/CadernetaReserva.css';

function Reserva() {
    const ref = useRef(null);
    const click = useReactToPrint({
      contentRef: ref,
      documentTitle: "Reserva"
    });
    return (

<main>  
  <Header />
  <HeaderTools />
    <section className='cadernetaReserva' ref={ref}> 
      <h2>CADERNETA DO RESERVA </h2>
      <div className='tablereserva'>
        <article> Nome Proprietário: <input type="text" maxLength='50px' /> </article>
        <article> Telefone Contato: <input type="text" maxLength='50px' /> </article>
        <article> <strong>MANTENHA-SE NAS LIMITAÇÕES DO RESERVA </strong></article>
        <article> Tamanho do reserva: <input type="text" maxLength='50px' /> </article>
        <article> Peso máximo do reserva: <input type="text" maxLength='50px' /> </article>
        <article> Velocidade máxima de comando: <input type="text" maxLength='50px' /> </article>


        <div className="table1">
        <table>
          <tr>
            <th> Fabricante do reserva </th>
            <th> Modelo do reserva </th>
            <th> No. de série do reserva </th>
            <th> Data de fabricação do reserva </th>
          </tr>
          
          <tr>
            <td> <input type="text" className='FabricanteReserva' placeholder='Fabricante Reserva' maxLength='5' /> </td>
            <td> <input type="text" className='ModeloReserva' placeholder='Modelo Reserva' maxLength='30' /> </td>
            <td> <input type="text" className='NoSerieReserva' placeholder='No de serie' maxLength='6' /> </td>
            <td> <input type="date" className='DataFabricacaoReserva' placeholder='Data de fabricação' maxLength='10' /> </td>
          </tr>
        </table>
      </div>

      <div className='table2'>
        <table>
          <tr>
            <th> Fabricante do Harness</th>
            <th> Modelo do Harness </th>
            <th> Número de série Harness </th>
            <th> Data fabricação Harness </th>
          </tr>

          <tr>
          <td> <input type="text" className='FabricanteHarness' placeholder='Fabricante Harness' maxLength='5' /> </td>
            <td> <input type="text" className='ModeloHarness' placeholder='Modelo Harness' maxLength='30' /> </td>
            <td> <input type="text" className='NoSerieHarness' placeholder='No de serie' maxLength='6' /> </td>
            <td> <input type="date" className='DataFabricacaoHarness' placeholder='Data de fabricação' maxLength='10' /> </td>          </tr>
        </table>
      </div>

      <div className='table3'>
        <table>
          <tr>
            <th> Fabricante do DAA </th>
            <th> Modelo do DAA </th>
            <th> Número de série DAA </th>
            <th> Data fabricação DAA </th>
          </tr>
          
          <tr>
          <td> <input type="text" className='FabricanteDaa' placeholder='Fabricante DAA' maxLength='5' /> </td>
            <td> <input type="text" className='ModeloDaa' placeholder='Modelo DAA' maxLength='30' /> </td>
            <td> <input type="text" className='NoSerieDaa' placeholder='No de serie DAA' maxLength='6' /> </td>
            <td> <input type="date" className='DataFabricacaoDaa' placeholder='Data de fabricação' maxLength='10' /> </td>          </tr>
        </table>
      </div>

      <div className='table4'>
        <table>
          <tr>
            <th> Data </th>
            <th> Local </th>
            <th> Rigger </th>
            <th> Serviço </th>
            <th> Assinatura Rigger </th>           
          </tr>
          
          <tr>
          <td> <input type="date" placeholder='Data' maxLength='5' /> </td>
            <td> <input type="text" placeholder='Local' maxLength='30' /> </td>
            <td> <input type="text" placeholder='Rigger' maxLength='20' /> </td>
            <td> <input type="text" placeholder='Serviço' maxLength='20' /> </td>          
            <td> <input type="text" placeholder='Assinatura' maxLength='20' /> </td>          
          </tr>

          <tr>
          <td> <input type="date" placeholder='Data' maxLength='5' /> </td>
            <td> <input type="text" placeholder='Local' maxLength='30' /> </td>
            <td> <input type="text" placeholder='Rigger' maxLength='20' /> </td>
            <td> <input type="text" placeholder='Serviço' maxLength='20' /> </td>          
            <td> <input type="text" placeholder='Assinatura' maxLength='20' /> </td>          
          </tr>

          <tr>
          <td> <input type="date" placeholder='Data' maxLength='5' /> </td>
            <td> <input type="text" placeholder='Local' maxLength='30' /> </td>
            <td> <input type="text" placeholder='Rigger' maxLength='20' /> </td>
            <td> <input type="text" placeholder='Serviço' maxLength='20' /> </td>          
            <td> <input type="text" placeholder='Assinatura' maxLength='20' /> </td>          
          </tr>

          <tr>
          <td> <input type="date" placeholder='Data' maxLength='5' /> </td>
            <td> <input type="text" placeholder='Local' maxLength='30' /> </td>
            <td> <input type="text" placeholder='Rigger' maxLength='20' /> </td>
            <td> <input type="text" placeholder='Serviço' maxLength='20' /> </td>          
            <td> <input type="text" placeholder='Assinatura' maxLength='20' /> </td>          
          </tr>

          <tr>
          <td> <input type="date" placeholder='Data' maxLength='5' /> </td>
            <td> <input type="text" placeholder='Local' maxLength='30' /> </td>
            <td> <input type="text" placeholder='Rigger' maxLength='20' /> </td>
            <td> <input type="text" placeholder='Serviço' maxLength='20' /> </td>          
            <td> <input type="text" placeholder='Assinatura' maxLength='20' /> </td>          
          </tr>

          <tr>
          <td> <input type="date" placeholder='Data' maxLength='5' /> </td>
            <td> <input type="text" placeholder='Local' maxLength='30' /> </td>
            <td> <input type="text" placeholder='Rigger' maxLength='20' /> </td>
            <td> <input type="text" placeholder='Serviço' maxLength='20' /> </td>          
            <td> <input type="text" placeholder='Assinatura' maxLength='20' /> </td>          
          </tr>

          <tr>
          <td> <input type="date" placeholder='Data' maxLength='5' /> </td>
            <td> <input type="text" placeholder='Local' maxLength='30' /> </td>
            <td> <input type="text" placeholder='Rigger' maxLength='20' /> </td>
            <td> <input type="text" placeholder='Serviço' maxLength='20' /> </td>          
            <td> <input type="text" placeholder='Assinatura' maxLength='20' /> </td>          
          </tr>

          <tr>
          <td> <input type="date" placeholder='Data' maxLength='5' /> </td>
            <td> <input type="text" placeholder='Local' maxLength='30' /> </td>
            <td> <input type="text" placeholder='Rigger' maxLength='20' /> </td>
            <td> <input type="text" placeholder='Serviço' maxLength='20' /> </td>          
            <td> <input type="text" placeholder='Assinatura' maxLength='20' /> </td>          
          </tr>

        </table>
        </div>
      </div>
      

      <div className='rigger'>
        
        <select className="Rigger" id="Rigger" >
          <option value="MaiconDouglas"> Maicon Douglas - Lineset </option>
          <option value="Pepe - SkyRadical"> Pepe - SkyRadical </option>
          <option value="Patrícia Porto - SkyRadical"> Patrícia Porto - SkyRadical </option>
        </select>
      </div>

      <div className="AssinaturaRigger">
        <article> <small> Quando seu rigger assinar digitalmente, aparecerá aqui a assinatura dele. </small></article>
        <img src='assinatura' alt='Assinatura do Rigger'/>
        <br />
      </div>
    </section>

    <section className="submit">
    <div className='btn_reserva'>   
        <button onClick={click}> Imprimir caderneta do reserva </button>    
        <button> Enviar para aprovação do Rigger </button>
      </div>

      <div>
        <article><small> OBS: Para salvar a caderneta e enviar para assinatura digital, você deve 
          efetuar o <button> <Link to="/Login"> Login. </Link> </button> </small> </article>
      </div> 
    </section>
    <Footer />
</main>
  )
}

export default Reserva;