import { Link } from "react-router";
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import VideoDestaque from '../componentes/VideoDestaque.js';
import '../styles/VideoAulas.css'

import BlueSkies from './VideoAulas/BlueSkies.js';
import BrianSaintGermain from './VideoAulas/BrianSaintGermain.js';
import FridayFreakout from './VideoAulas/FridayFreakout.js';
import PauloAssis from './VideoAulas/PauloAssis.js';
import PauNaMaquina from './VideoAulas/PauNaMaquina.js';
import WindUp from './VideoAulas/WindUp.js';


function VideoAulas() {
  return (
<main>  
<Header />

<section className='video-aulas'> 
    <h2> Vídeo-Aulas </h2>
    <article> Encontre aqui materiais extra de estudo e curiosidades, no formato de vídeos. Tudo está separado por tópicos, para facilitar sua busca. </article> <br/>

    <section>
    <VideoDestaque />
    </section>

    <div className='btnVideoAulasRecomendados'>
        <article> Os conteúdos mais recomendados estão aqui.</article>
        <button> Canal Oficial ABRA </button> 
        <button> ABRA e convidados </button> <br/>
        <button> ABRA Safety Meetings </button>
        <Link to="/"><button> APF Cutaway </button></Link>
        <br/>
    </div>

    <div className='btnVideoAulas'>
        <h3> ACHE SEU CANAL EM ORDEM ALFABÉTICA.</h3>
        <button> ABRA - Canal Oficial </button> 
        <button> ABRA e convidados </button>
        <button> ABRA - Safety Meetings </button>
        <Link to="/"><button> APF Cutaway </button></Link>
        <Link to="/BlueSkies"><button> Blue Skies - Fun Day </button></Link>
        <Link to="/BrianSaintGermain"><button> Brian Saint Germain </button></Link>
        <Link to="/FridayFreakout"><button> Friday Freakout </button></Link>
        <Link to="/PauloAssis"><button> O homem aventura </button></Link>
        <Link to="/PauNaMaquina"><button> Pau na Máquina </button></Link>
        <Link to="/WindUp"><button> Wind Up </button> </Link>
        
    </div>

    <div className='canalABRA1'>
    <h2> CANAL DA ABRA </h2>  
        <article> Veja os melhores vídeos de instrução da Academia Brasileira de Paraquedismo. </article>
        <iframe width="420" height="315" src="https://www.youtube.com/embed/zjLBUiWv09g"> </iframe>
        <iframe width="420" height="315" src="https://www.youtube.com/embed/FIhV0YIA-Vg&t=9s"> </iframe> <br/>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/Z9l-MC7aXDE&t=21s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/ZCCBSq3uJMc"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/dmSmImD_YSQ"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/Wb_FwCP7oRY&t=24s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/38OSQDziuTI"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/vdzYo7qIGpM"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/NYpt5r7NgFM"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/6Iy-gTr5UDw"> </iframe>
    </div>

    <div className='canalABRA2'>
    <h3> CANAL DA ABRA - Convidados </h3>  
        <article> Veja os melhores vídeos com convidados, da Academia Brasileira de Paraquedismo. </article>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/FrQKnReOYzo"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/E2WGJiQ228M"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/1T65PaYztSk"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/mx3hm3GU-fA"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/06gVmWTfaE8"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/Du7qaawPjf4"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/KAj2pwEmLGA"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/by6J7Bqe7TQ"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/HhlT0eVYCYU"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/EKIXfspURFo"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/n5BEtUkHyuo"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/udUkJdH19ao"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/7BSl4wSl6Uo"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/xyAmDcCB2hY"> </iframe>
    </div>

    <div className='canalABRA3'>
    <h3> CANAL DA ABRA - Safety Meetings </h3>  
        <article> Veja as Safety Meetings realizadas durante a Pandemia, com os melhores instrutores do Brasil. </article>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/3NzdjSsD-ug&t=1984s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/fOCnI-nAoz4"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/PMgbF4IMUFA"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/X2pZCTqbt20&t=1571s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/LmM9ANA5ze4"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/oedPAbUcPTU"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/T6uGmmNqluo"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/QVpOhmiClmY&t=2081s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/Ts8-ZgSDl_s&t=150s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/Vh1sg7DpfAE&t=3958s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/LTCMBflNNv4&t=1579s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/e9ECqVKbx5U&t=4121s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/-npbWdiPlw8"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/XY22V-ylBmM"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/9--NE6NiguI"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/tU6vUS5rzPk"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/WRjgATLvBzI"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/LG1fOse_pmE&t=12s"> </iframe>
    </div>

    <div className='apf'>
    <h3> APF - CUTAWAY </h3>  
        <article> Veja o material da APF sobre Emergências. </article>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/PTYsjH7DSVA&list=PLHdmFp89rAHBsZwLtEzkZ8wWeRk7GCrTt&index=1"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/MZx3lN-lwok&list=PLHdmFp89rAHBsZwLtEzkZ8wWeRk7GCrTt&index=2"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/TsxYEbLNh_8&list=PLHdmFp89rAHBsZwLtEzkZ8wWeRk7GCrTt&index=3"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/YD1we-F9-3c&list=PLHdmFp89rAHBsZwLtEzkZ8wWeRk7GCrTt&index=4"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/gpZ_GeJ1rxk&list=PLHdmFp89rAHBsZwLtEzkZ8wWeRk7GCrTt&index=5"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/UL87Ceo02L4&list=PLHdmFp89rAHBsZwLtEzkZ8wWeRk7GCrTt&index=6"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/iBNf-HsD3Ms&list=PLHdmFp89rAHBsZwLtEzkZ8wWeRk7GCrTt&index=7"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/S4x1ptlBF1Q&list=PLHdmFp89rAHBsZwLtEzkZ8wWeRk7GCrTt&index=8"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/79TWUxK1q2o&list=PLHdmFp89rAHBsZwLtEzkZ8wWeRk7GCrTt&index=9"> </iframe>
    </div>

</section>

<Footer />
</main>
  )
}

export default VideoAulas;