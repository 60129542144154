import { Link } from "react-router";
import '../styles/Componentes/Normas.css'

function Normas() {
  return (
<main>  

<div className="normas">
    <section className='normasintegra'>
        <h3> LEIA AS NORMAS NA ÍNTEGRA </h3>
        <article> Consulte no site das entidades sobre as normas completas. </article>
        <a href="https://abra.org.br/codigo-desportivo/" target="_blank"> <button> ABRA </button> </a>
        <a href="https://abpqd.com.br/" target="_blank"> <button> ABPq </button> </a>
        <a href="https://www.cbpq.org.br/documentos/estatuto-codigo-esportivo/" target="_blank"><button> CBPq </button> </a>
        <a href="https://www.uspa.org/rating-holders-and-s-tas/sim-irm" target="_blank"> <button> USPA </button> </a>
        <a href="" target="_blank"> <button> CNP - Boituva </button> </a>
    </section>
</div>
</main>
  )
}

export default Normas;