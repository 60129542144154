import { Link } from "react-router";
import '../styles/Componentes/VideoDestaque.css';

function VideoDestaque() {
  return (
<main>  

<section className="VideoDestaque">
<div>
    <h3> VÍDEOS EM DESTAQUES </h3>
    <iframe width="420" height="315" src="https://www.youtube.com/embed/zjLBUiWv09g"> </iframe>
    <iframe width="420" height="315" src="https://www.youtube.com/embed/LmM9ANA5ze4"> </iframe>
</div>
</section>  

</main>
  )
}

export default VideoDestaque;