import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';
import VideoDestaque from '../../componentes/VideoDestaque.js';
import '../../styles/VideoAulas1.css'

function pauNaMaquina() {
  return (
<main>  
<Header />

<section className='videoaulas1'>
<h2> PAU NA MÁQUINA - PODCAST </h2>
    <article> Encontre aqui os vídeos do podcast <strong>Pau Na Máquina</strong> feitos pelo Edu Esteves e o Max.</article> <br/>

    <section>
    <VideoDestaque />
    </section>

    <div className='windup'>
    <h2> PAU NA MÁQUINA </h2>  
    <article> Veja os vídeo do podcast Pau na Máquina sobre paraquedismo. </article> <br/>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/_nd3FjnzoqA&list=PLuezlTqukLL8_KTtx-mdypwhzFucU689m&index=7"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/kvXqof9gRrY"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/9-k8hgohSdU"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/5NwVdy-5g0A"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/IS-6xtDrGmA"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/4kwnRB2jFwU"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/tTNTB4D66P8&t=16s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/DnLKP1X4eUQ&t=1s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/iMFsaTIoDF4"> </iframe>
    </div>

</section>

<Footer />
</main>
  )
}

export default pauNaMaquina; 