import { Link } from "react-router";
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import Cadastro from './Cadastro.js';

function Login() {
    return (
  <main>  
  <Header />
    <section>
        <h2> LOGIN </h2>
        <article> E-mail </article>
        <input type="text" maxLength='50' />
        <article> Senha </article>
        <input type="passwor" />
        <br/>
        
        <article> Se ainda não tem acesso </article>
        <button><Link to="/Cadastro"> Faça seu CADASTRO </Link></button>
    </section>
  <Footer />
</main>
  )
}

export default Login;