import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router";
import App from './App';
import Contato from './pages/Contato.js';
import Login from './pages/Login.js';
import Cadastro from './pages/Cadastro.js';
import SaibaMais from './pages/SaibaMais.js';

import Normas from './componentes/Normas.js';
import VideoDestaque from './componentes/VideoDestaque.js';
import Parceiros from './componentes/Parceiros.js';
import HeaderTools from './componentes/HeaderTools.js';

import CatAi from './pages/CatAi/CatAi.js';
import HeaderAi from './componentes/HeaderAi.js';
import ApostilaAff from './pages/CatAi/ApostilaAff.js';
import ExtraCatAi from './pages/CatAi/ExtraCatAi.js';
import FichaProgAi from './pages/CatAi/FichaProgAi.js';
import NormasCatAi from './pages/CatAi/NormasCatAi.js';
import ProvaAff from './pages/CatAi/ProvaAff.js';
import ProvaCatAi from './pages/CatAi/ProvaCatAi.js';

import CatA from './pages/CatA/CatA.js';
import CatB from './pages/CatB/CatB.js';
import CatC from './pages/CatC/CatC.js';
import CatD from './pages/CatD/CatD.js';

import Conversor from './pages/Tools/Conversor.js';
import CargaAlar from './pages/Tools/CargaAlar.js';
import Caderneta from './pages/Tools/CadernetaSaltos.js';
import FichaOnline from './pages/Tools/FichaOnline.js';
import CadernetaReserva from './pages/Tools/CadernetaReserva.js';

import VideoAulas from './pages/VideoAulas.js';
import BlueSkies from './pages/VideoAulas/BlueSkies.js';
import BrianSaintGermain from './pages/VideoAulas/BrianSaintGermain.js';
import FridayFreakout from './pages/VideoAulas/FridayFreakout.js';
import PauNaMaquina from './pages/VideoAulas/PauNaMaquina.js';
import PauloAssis from './pages/VideoAulas/PauloAssis.js';
import WindUp from './pages/VideoAulas/WindUp.js';

import Coaches from './pages/Coaches.js';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/Contato" element={<Contato />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/Cadastro" element={<Cadastro />} />
      <Route path="/SaibaMais" element={<SaibaMais />} />
    
      <Route path="/Normas" element={<Normas />} />
      <Route path="/VideoDestaque" element={<VideoDestaque />} />
      <Route path="/Parceiros" element={<Parceiros />} />
      <Route path="/HeaderTools" element={<HeaderTools />} />

      <Route path="/catAi" element={<CatAi />} />
      <Route path="/HeaderAi" element={<HeaderAi />} />
      <Route path="/ApostilaAff" element={<ApostilaAff />} />
      <Route path="/ExtraCatAi" element={<ExtraCatAi />} />
      <Route path="/FichaProgAi" element={<FichaProgAi />} />
      <Route path="/NormasCatAi" element={<NormasCatAi />} />
      <Route path="/ProvaAff" element={<ProvaAff />} />
      <Route path="/ProvaCatAi" element={<ProvaCatAi />} />

      <Route path="/catA" element={<CatA />} />
      <Route path="/catB" element={<CatB />} />
      <Route path="/catC" element={<CatC />} />
      <Route path="/catD" element={<CatD />} />

      <Route path="/Conversor" element={<Conversor />} />
      <Route path="/CargaAlar" element={<CargaAlar />} />
      <Route path="/Caderneta" element={<Caderneta />} />
      <Route path="/FichaOnline" element={<FichaOnline />} />
      <Route path="/CadernetaReserva" element={<CadernetaReserva />} />
      
      <Route path="/videoAulas" element={<VideoAulas />} />
      <Route path="/BlueSkies" element={<BlueSkies />} />
      <Route path="/BrianSaintGermain" element={<BrianSaintGermain />} />
      <Route path="/FridayFreakout" element={<FridayFreakout />} />
      <Route path="/PauNaMaquina" element={<PauNaMaquina />} />
      <Route path="/PauloAssis" element={<PauloAssis />} />
      <Route path="/WindUp" element={<WindUp />} />

      <Route path="/Coaches" element={<Coaches />} />

    </Routes>
  </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
