import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';


function ExtraCatAi() {
  return (
<main>  
<Header />

<section className='extracatai'>
<h2> MATERIAL EXTRA - CAT AI </h2>
    <p> Material Extra de Categoria AI. </p>
</section>

<Footer />
</main>
  )
}

export default ExtraCatAi;