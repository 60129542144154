import { Link } from "react-router";
import '../styles/Categoria/HeaderCat.css';

function HeaderAi() {
  return (
<main>  

<section className="HeaderAi">
    <h1>CATEGORIA AI </h1>
</section>  

</main>
  )
}

export default HeaderAi;
