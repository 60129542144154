import Header from '../../componentes/Header';
import HeaderTools from '../../componentes/HeaderTools';
import Footer from '../../componentes/Footer';
import { Link } from 'react-router';
import {useState} from 'react';
import '../../styles/Tools/Conversor.css';

function Conversor() {

    const [input, setInput] = useState(1);
    const [from, setFrom] = useState('Km/h (Kilometros por hora)');
    const [to, setTo] = useState('Kn (milhas náuticas por hora - Knots)');
    const [output, setOutput] = useState(0);
    const [message, setMessage] = useState('');

    //Selecionar Elementos 

    function convert() {
    
        if(from === to) {
            setOutput(input);
            setMessage('');
            return;
        }
    
        //converter para km/hr
        let kilometer
        switch(from) {
            case "Km/h (Kilometros por hora)":
                kilometer = input;
            break
    
            case "Kn (milhas náuticas por hora - Knots)":
                kilometer = input * 1.85;
            break
            
            case "M/s (metros por segundo)":
                kilometer = input * 3.6;
            break
    
            case "Mph (Milhas por hora)":
                kilometer = input * 1.61;
            break
        }
    
        //converter metros para outras unidades
        let result
        switch(to) {
            case "Km/h (Kilometros por hora)":
                result = kilometer;
            break
    
            case "Kn (milhas náuticas por hora - Knots)":
                result = kilometer * 0.54;
            break
    
            case "M/s (metros por segundo)":
                result = kilometer * 0.28;
            break
    
            case "Mph (Milhas por hora)":
                result = kilometer * 0.62;
            break
        }
    
    result = Math.ceil(result);
    //exibir resultado do output
    setOutput(result);
    
    // exibir resultado da mensagem
    
    setMessage(`${Math.ceil(input)} ${from} equivalem a ${result} ${to}`);
    
    return;
    }
    


return (
<main>  
<Header />

<HeaderTools />
<div className="container">

    <h1> Conversor de Ventos </h1>

    <div className="input-group">
        <label htmlFor="input"> Digite o valor </label>
        <input type="number" id="input" name="ïnput" placeholder="0" value={input} 
        onChange={(event) => 
            {setInput(event.target.value)
                setOutput(0)
            }} /> 

    </div>

    <div className="input-group">
        <label htmlFor="ïnput"> Selecione a medida atual </label>
        <select name="from" id="from" value={from} onChange={(event) => setFrom(event.target.value)}>
            <option value="Km/h (Kilometros por hora)"> Km/h (Kilometros por hora) </option>
            <option value="Kn (milhas náuticas por hora - Knots)"> Kn (milhas náuticas por hora - Knots) </option>
            <option value="M/s (metros por segundo)"> M/s (metros por segundo) </option>
            <option value="Mph (Milhas por hora)"> Mph (Milhas por hora) </option>
        </select>
    </div>

    <div className="input-group">
        <label htmlFor="input"> Selecione como quer a informação </label>
        <select name="to" id="to" value={to} onChange={(event) => setTo(event.target.value)}>
            <option value="Km/h (Kilometros por hora)"> Km/h (Kilometros por hora) </option>
            <option value="Kn (milhas náuticas por hora - Knots)"> Kn (milhas náuticas por hora - Knots) </option>
            <option value="M/s (metros por segundo)"> M/s (metros por segundo) </option>
            <option value="mMph (Milhas por hora)"> Mph (Milhas por hora) </option>
        </select>
    </div>

    <button id="convert-btn" onClick={convert}> Converter </button>
        <br/>        
    <div className="output-group">
        <label htmlFor="output"> Resultado </label>
        <input type="number" id="output" readonly value={output} /> 
    </div>
        <br/>
    <div> <article> {message} </article> </div>

    <div id="message"> 
        <h3> Vento Recomendado por categoria </h3>
        <article><small> Vento para Cat AI - até 26 km/h </small></article>
        <article><small> Vento para Cat A - até 26 km/h </small></article>
        <article><small> Vento para Cat B - até 28 km/h </small></article>
        <article><small> Vento para Cat C - até 30 km/h </small></article>
        <article><small> Vento para Cat D - até 32 km/h </small></article>
        <br/>
    </div>
</div>
<Footer />
</main>
  )
}

export default Conversor;
