import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';
import VideoDestaque from '../../componentes/VideoDestaque.js';
import '../../styles/VideoAulas1.css'

function windUp() {
  return (
<main>  
<Header />

<section className='videoaulas1'>
<h2> VÍDEOS DO WIND UP </h2>
    <article> Encontre aqui os melhores vídeos do <strong>Wind Up</strong> que foram parar na mídia.</article> <br/>

    <section>
    <VideoDestaque />
    </section>

    <div className='windup'>
    <h2> WINDUP </h2>  
        <article> Veja reportagens sobre o Wind Up na mídia, quando ele começou como o primeiro túnel de vento de São Paulo. </article> <br/>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/oDKXf8JjUnE&list=PLuezlTqukLL8_KTtx-mdypwhzFucU689m&index=1&pp=gAQBiAQB"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/BFbCxzSHD4c&list=PLuezlTqukLL8_KTtx-mdypwhzFucU689m&index=2"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/B2_sAAHhf7Q&list=PLuezlTqukLL8_KTtx-mdypwhzFucU689m&index=3"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/qOdiXcRWDi4&list=PLuezlTqukLL8_KTtx-mdypwhzFucU689m&index=4"> </iframe>
    </div>

</section>

<Footer />
</main>
  )
}

export default windUp; 