import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';
import VideoDestaque from '../../componentes/VideoDestaque.js';
import '../../styles/VideoAulas1.css'

function BrianSaintGermain() {
  return (
<main>  
<Header />

<section className='videoaulas1'>
<h2> Brian Saint Germain </h2>
    <article> Encontre aqui alguns dos vídeos deste guru de segurança e pouso: <strong> Brian Saint Germain</strong>.</article> <br/>

    <section>
    <VideoDestaque />
    </section>

    <div className='brianSaintGermain'>
    <h2> Brian Saint Germain </h2>  
    <div className='brian'>
    <h3> BRIAN SAINT GERMAIN </h3>  
        <article> Veja os vídeos mais acessados do escritor e Guru de segurança em Pouso, Brian Saint Germain. </article> <br/>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/CdKDsCezpRs&t=17s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/U16yaZkV8dQ"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/HgV6eGYCzgo"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/mtcVcccwG7g"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/-nWdJ7mZ_TU"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/ufm2XsRYdQA"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/qqzlwKhu5Rg"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/NSJk8iiHAEs"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/HWpCiJuzuik"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/9T7F4mLemzs"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/2Jmaa9vj4vw"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/5V9Ior7gp3c"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/RHGnWIavHoM"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/oXTos6pGJhQ"> </iframe>
    </div>


    </div>

</section>

<Footer />
</main>
  )
}

export default BrianSaintGermain; 