import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';
import FichaDownload from '../../files/fichaProgAi.pdf';


function FichaProgAi() {
  return (
<main>  
<Header />

<section className='fich_prog_A'> 
    <h2> Ficha de Progressão </h2>
    <article> <a href={FichaDownload} download> Faça o download da Ficha de Progressão para Categoria A em branco </a></article> <br/>
    <article> Ou veja os briefings da ficha abaixo e use nossa Ficha Online.</article>
    <article> <small> Você deverá efetur o login para salvar a ficha digitalmente e solicitar assinatura.</small> </article>
    <br/>

        <div className='table'>
        <table>
          <tr>
            <th> Check </th>
            <th>Atividade </th>
            <th> Salto no. </th>
            <th> Data </th>
            <th> Assinatura </th>
          </tr>

          <tr>
            <article> 1. TEORIA E EMERGÊNCIAS </article>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 1.1 Revisão Teórica de Emergências </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className="AssinaturaCatAi11" maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 1.2 Prova prática - resposta a situações de emergências - equipamento suspenso </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi12' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 1.3 Prova oral sobre funcionamento do RSL e DAA </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi13' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 1.4 Prova teórica para cat A corrigida - 80% de acerto ou refazer a prova </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi14' maxLength='10' /> </td>
          </tr>

          <tr> <article>2. EQUIPAMENTO </article></tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 2.1 Realizou salto com a própria dobragem </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi21' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 2.2 Briefing sobre cálculo de carga alar </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi22' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 2.3 Realizou check de equipamento de outro paraquedista, identificando
            perigos de equipagem </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi23' maxLength='10' /> </td>
          </tr>

          <tr><article> 3. AERONAVE</article></tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 3.1 Demonstrou compreensão sobre necessidade de cinto de segurança e
            capacete para decolar </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi31' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 3.2 Demonstrou compreensão sobre padrões de decolagem e
            lançamento das aeronaves </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi32' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 3.3 Briefing sobre vento de camada </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi33' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 3.4 Briefing sobre peso e balanceamento, efeito nos comandos quando
há paraquedistas do lado de fora da aeronave, reta de lançamento,
ponto de saída e radiocomunicação </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi34' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 3.5 Em um salto, definiu apropriadamente o PS em solo e verificou PS na
            saída </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi35' maxLength='10' /> </td>
          </tr>

          <tr><article> 4. QUEDA LIVRE</article></tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 4.1 Demonstrou habilidade nos movimentos acima e abaixo</article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi41' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 4.2 Demonstrou habilidade na aproximação em rampa </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi42' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 4.3 Demonstrou habilidade em grip com outro paraquedista,
            aproximando com segurança </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi43' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 4.4 Demonstrou habilidade em heading no track de
            separação, separar pelo menos 50 metros, wave-off </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi44' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 4.5 Demonstrou habilidade em controle de eixos, salto com
            backloop, frontloop e barrelroll </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi45' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 4.6 Demonstrou habilidade de curva no eixo (braço) </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi46' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 4.7 Saída a baixa altura (4500ft), comando estável em até 5
            segundos após saída </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi47' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 4.8 Completou 25 saltos </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi48' maxLength='10' /> </td>
          </tr>

          <tr><article> 5. VELAME </article></tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.1 Demonstrou capacidade em planejar navegação conforme
            padrões da área, e corrigir a navegação de forma segura </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi51' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.2 Demonstrou capacidade de rolamento em um pouso </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi52' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.3 Pousou pelo menos 5 vezes em pé, em até 20 metros do
            alvo pré-determinado, sem auxílio de rádio </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi53a' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.3 Pousou pelo menos 5 vezes em pé, em até 20 metros do
            alvo pré-determinado, sem auxílio de rádio </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi53b' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.3 Pousou pelo menos 5 vezes em pé, em até 20 metros do
            alvo pré-determinado, sem auxílio de rádio </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi53c' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.3 Pousou pelo menos 5 vezes em pé, em até 20 metros do
            alvo pré-determinado, sem auxílio de rádio </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi53d' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.3 Pousou pelo menos 5 vezes em pé, em até 20 metros do
            alvo pré-determinado, sem auxílio de rádio </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi53e' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.4 Briefing e execução de curva rasa (flat turn) </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi54' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.5 Briefing e execução de curvas de tirantes traseiro - freios
              feitos e desfeitos </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi55' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.6 Briefing e execução de navegação a meio freio </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi56' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.7 Simulação de pane de freio </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi57' maxLength='10' /> </td>
          </tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article> 5.8 Briefing sobre como identificar áreas turbulentas </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAi58' maxLength='10' /> </td>
          </tr>

          <tr> <article> APTO PARA MUDANÇA DE CATEGORIA </article></tr>

          <tr>
            <td> <label> Checado <input type='checkbox' /></label> </td>
            <td> <article>A ABRA - Academia Brasileira de Paraquedismo certifica que o atleta acima qualificado está apto para a CATEGORIA A. </article></td>
            <td> <input type="text" placeholder='SaltoNo' maxLength='3' /> </td>
            <td> <input type="date" placeholder='Data' maxLength='12' /> </td>
            <td> <input type="text" placeholder='Assinatura' className='AssinaturaCatAiFinal' maxLength='10' /> </td>
          </tr>


        </table>
      </div>

      <div className='btn_fichaprogai'> 
        <button onClick={(event) => {window.print()}}> Imprimir Ficha de progressão </button>   
        <button onClick={(event) => {}}> Imprimir ficha </button> 
        <article> Selecione as atividades que você realizou pelo botão de Check, preencha data e número de salto e envie para o seu instrutor para assinatura digital. </article>   
          <div className='instrutor'>    
            <select className="Instrutor" id="Instrutor" >
              <option value="Vivi - SkyRadical"> Vivi - SkyRadical </option>
              <option value="Levi - SkyRadical"> Levi - SkyRadical </option>
              <option value="Paulo Assis - SkyRadical"> Paulo Assis - SkyRadical </option>
            </select> <br/>
            <button> Enviar para aprovação do Instrutor </button>
          </div>
      </div>

</section>
<Footer />
</main>
  )
}

export default FichaProgAi;

