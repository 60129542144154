import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';
import Normas from '../../componentes/Normas';
import HeaderAi from '../../componentes/HeaderAi.js';
import '../../styles/Categoria/NormasCat.css';

function NormasCatAi() {
  return (
<main>  
<Header />
<HeaderAi />
<section className='normascatai'>
<h2> NORMAS </h2>
    <article> Normas da Categoria AI por tópico. </article>
    <article> IMPORTANTE: Se você está na progressão AFF ou ASL, consulte sempre seu instrutor! </article>
    
    
  <h3> TÓPICOS </h3>  
  
  <section className="cursoTeorico">
    <h4> CURSO TEÓRICO </h4>
    <article> ABRA: Lorem Ipsum </article>
  </section>
      
  <section className="reciclagem">
    <h4> RECICLAGEM </h4>
    <article> Lorem Ipsum </article>
  </section>
  
  <section className="vento">
    <h4> VENTO </h4>
    <article> Lorem Ipsum </article>
  </section>
  
  <section className="velame">
    <h4> VELAME </h4>
    <article> Lorem Ipsum </article>
  </section>

  <section className="alturaComando">
    <h4> ALTURA DE COMANDO </h4>
    <article> Lorem Ipsum </article>
  </section>

  <section className="tamanhoAreaPouso">
    <h4> TAMANHO DA ÁREA DE POUSO </h4>
    <article> Lorem Ipsum </article>
  </section>

  <section className="tempoSemSaltar">
    <h4> TEMPO SEM SALTAR </h4>
    <article> Lorem Ipsum </article>
  </section>

  <section className="oquefazer">
    <h4> O QUE VOCÊ PODE FAZER? </h4>
    <article> Lorem Ipsum </article>
  </section>

</section>

<section>
  <Normas />
</section>

<Footer />
</main>
  )
}

export default NormasCatAi;