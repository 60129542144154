import { Link } from "react-router";
import Header from '../../componentes/Header.js';
import HeaderTools from '../../componentes/HeaderTools.js';
import Footer from '../../componentes/Footer.js';
import '../../styles/Tools/CargaAlar.css'
import {useState} from 'react';


function CargaAlar() {

//Selecionar Elementos
const [peso, setPeso] = useState(0);
const [velame, setVelame] = useState(0);
const [output, setOutput] = useState(0);
const [message, setMessage] = useState('');

//Função para converter as unidades

function calcular() {
    if(peso <= 0 || velame <= 0) {
        setMessage('Definir valores de peso e velame');
        return;
    }

    //calcular
    let result = ((((peso + 12)*2.2)/velame)/100).toFixed(2);  
 
    //exibir resultado do output
    setOutput(result);

// exibir resultado da mensagem
setMessage(`Com peso de ${Math.ceil(peso)} e velame do tamanho ${velame}, sua carga alar é de ${result}`);
return;
}

  return (
<main>  
<Header />
<HeaderTools />
<div class="container">

    <h1> C&aacute;lculo de Carga Alar </h1>

    <div class="input-group">
        <label for="input"> Peso Equipado </label>
        <input type="number" id="peso" name="peso" placeholder='0' maxlength="3" 
        onChange={(event) => 
            {setPeso(event.target.value)
                setOutput(0)
            }} /> 
    </div>
        <br/>
    <div class="input-group">
        <label for="input"> Tamanho do velame </label>
        <input type="number" id="velame" name="velame" placeholder='0' maxlength="3" 
        onChange={(event) => 
            {setVelame(event.target.value)
                setOutput(0)
            }} /> 
    </div>
        <br/>
    <button id="calculate-btn"  onClick={calcular}> Calcular </button>
        <br/>
    <div className="output-group">
        <label htmlFor="output"> Resultado </label>
        <input type="number" id="output" readonly value={output} /> 
    </div>
        <br/>
    <div> <p> {message} </p> </div>
    <div id="message"> 
        <h3> SOBRE O CÁLCULO DE CARGA ALAR </h3>
            <article> A fórmula do Cálculo de Carga Alar é: (Peso Equipado x 2,2)/Tamanho Velame em ft2 </article>
            <article> Esta ferramenta considera 12kg de equipamento para fins de cálculo. </article>
        <h3> CARGA ALAR POR CATEGORIA </h3>
            <article class="p_mensagem"> Categoria AI <i class="fas fa-chevron-circle-right"></i> entre 0.6 e 1.0</article>
            <article class="p_mensagem"> Categoria A <i class="fas fa-chevron-circle-right"></i>  até 1.1</article>
            <article class="p_mensagem"> Categoria B <i class="fas fa-chevron-circle-right"></i>  até 1.3</article>
            <article class="p_mensagem"> Categoria C <i class="fas fa-chevron-circle-right"></i>  até 1.5</article>
            <article class="p_mensagem"> Categoria D <i class="fas fa-chevron-circle-right"></i>  Sem limite</article>    
    </div>
    <br/>
</div>


<Footer />
</main>
  )
}

export default CargaAlar;