import { Link } from "react-router";
import Header from '../../componentes/Header.js';
import HeaderTools from '../../componentes/HeaderTools.js';
import Footer from '../../componentes/Footer.js';
import Login from '../Login.js';
import FichaProgAi from '../CatAi/FichaProgAi.js';
//import FichaProgA from './CatAi/FichaProgA.js';
//import FichaProgB from './CatAi/FichaProgB.js';
//import FichaProgC from './CatAi/FichaProgC.js';
import '../../styles/Tools/FichaOnline.css'

function FichaOnline() {
  return (
<main>  

<Header />
<HeaderTools />

<section>
    <h1> FICHAS ONLINE </h1>
    <article>Escolha qual Ficha de Progressão você gostaria de consultar.</article>
    <article> <small>OBS: Para salvar as fichas e enviar atividades para assinatura digital, você deve efetuar o <Link to="/Login"> Login. </Link> </small> </article>
    <button><Link to="/FichaProgAi"> FICHA PARA CATEGORIA AI </Link></button> <br/>
    <button><Link to="/FichaProgA"> FICHA PARA CATEGORIA A </Link></button> <br/>
    <button><Link to="/FichaProgB"> FICHA PARA CATEGORIA B </Link></button> <br/>
    <button><Link to="/FichaProgC"> FICHA PARA CATEGORIA C </Link></button> <br/>
</section>
<br/>
<Footer />
</main>
  )
}

export default FichaOnline;