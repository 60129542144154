import { Link } from "react-router";
import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';
import VideoDestaque from '../../componentes/VideoDestaque.js';
import '../../styles/VideoAulas1.css'

function pauloAssis() {
  return (
<main>  
<Header />

<section className='videoaulas1'>
<h2> PAULO ASSIS: O HOMEM AVENTURA </h2>
    <article> Relembre o clássico seriado de Paulo Assis sobre paraquedismo na Rede Globo na década de 90.</article> <br/>

    <section>
    <VideoDestaque />
    </section>

    <div className='windup'>
    <h2> O HOMEM VENTURA </h2>  
    <article> Veja os clássicos do Paulo Assis, o homem aventura da Rede Globo. </article> <br/>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/7wUPDfqlmKo"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/dXAbhoHORJM"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/87jA9K6P6fM"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/AKmcRZ2eNgo"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/i-KaXUgaI-0"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/ZrK0AOrUFRU&t=8s"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/7HILGP44pd8"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/iXukxK5uwSs"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/BSebLlHvjUw"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/7dgu67cLqr8"> </iframe>
        <iframe width="300" height="200" src="https://www.youtube.com/embed/JZYuW7RhHvM"> </iframe>
    </div>

</section>

<Footer />
</main>
  )
}

export default pauloAssis; 