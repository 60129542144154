import { Link } from "react-router";
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import Login from './Login.js';

function Cadastro() {
    return (
  <main>  
  <Header />
  <section>
  <h2> CADASTRO </h2>
        <article> Nome </article>
        <input type="text" maxLength='50' />
        <article> E-mail </article>
        <input type="text" maxLength='50' />
        <article> Telefone </article>
        <input type="number" maxLength='15' />
        <article> Data de Nascimento </article>
        <input type="date" maxLength='12' />
        <article> Senha </article>
        <input type="passwor" />
        <article> Confirme a Senha </article>
        <input type="passwor" />
        <br/>
        
        <article> Se você já tem acesso </article>
        <button><Link to="/Login"> Faça seu LOGIN. </Link></button> 
</section>
  <Footer />
</main>
  )
}

export default Cadastro;