import { Link } from "react-router";
import {useRef} from 'react';
import {useReactToPrint} from 'react-to-print';

import Header from '../../componentes/Header.js';
import HeaderTools from '../../componentes/HeaderTools.js';
import Footer from '../../componentes/Footer.js';
import Login from '../Login.js';
import '../../styles/Tools/CadernetaSaltos.css';

function Caderneta() {
    const ref = useRef(null);
    const click = useReactToPrint({
      contentRef: ref,
      documentTitle: "Caderneta"
  });

  return (
  <main>  
    <Header />
    <HeaderTools />

    <section className='caderneta' ref={ref}> 
      <h2>CADERNETA DE SALTO</h2>
      <div className='table1'>
        <table>
          <tr>
            <th>Salto no.</th>
            <th> DZ </th>
            <th> Altitude </th>
            <th> Tempo QL </th>
          </tr>
          <tr>
            <td> <input type="text" placeholder='Salto no.' maxLength='5' /> </td>
            <td> <input type="text" placeholder='Área de salto' maxLength='30' /> </td>
            <td> <input type="text" placeholder='Altitude' maxLength='6' /> </td>
            <td> <input type="text" placeholder='Tempo de Queda livre' maxLength='10' /> </td>
          </tr>
        </table>
      </div>

      <div className='table2'>
        <table>
          <tr>
            <th> Data</th>
            <th> Aeronave </th>
            <th> Equipamento </th>
            <th> Tempo Total </th>
          </tr>
          <tr>
            <td> <input type="date" placeholder='Data' maxLength='12' /></td>
            <td> <input type="text" placeholder='Aeronave' maxLength='20' /> </td>
            <td> <input type="text" placeholder='Equipamento' maxLength='10' /> </td>
            <td> <input type="text" placeholder='Tempo total' maxLength='10' /> </td>
          </tr>
        </table>
      </div>

      <div className='inputs'>
        <textarea className="TipoSalto" cols='30' rows='1' placeholder='Tipo de Salto' /> <br />
        <textarea className="Descricao" cols="30" rows="5" placeholder='Descrição Salto'></textarea>
        <br />
      </div>

      <div className='instrutor'>    
        <select className="Instrutor" id="Instrutor" >
          <option value="Vivi - SkyRadical"> Vivi - SkyRadical </option>
          <option value="Levi - SkyRadical"> Levi - SkyRadical </option>
          <option value="Paulo Assis - SkyRadical"> Paulo Assis - SkyRadical </option>
        </select>
      </div>

      <div>
        <article> <small> Quando seu instrutor assinar digitalmente, aparecerá aqui a assinatura dele. </small></article>
        <img src='assinatura' alt='Assinatura do Instrutor'/>
        <br />
      </div>
    </section>

    <section className="submit">
    <div className='btn_caderneta'> 
        <button onClick={click}> Imprimir caderneta de saltos </button>    
        <button> Enviar para aprovação do Instrutor </button>
      </div>

      <div>
        <article><small> OBS: Para salvar a caderneta e enviar para assinatura digital, você deve 
          efetuar o <button> <Link to="/Login"> Login. </Link> </button> </small> </article>
      </div>
    </section>

  <Footer />
</main>
  )
}

export default Caderneta;